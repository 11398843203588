<template>
    <v-container>
        <v-data-table :loading="loading_data_table" :headers="headers" :items="costs" :pageCount="numberOfPagesPaginate"
            :page="currentPagePaginate" :server-items-length="totalStagesPaginate" :options.sync="options"
            class="elevation-1" :footer-props="{
                itemsPerPageText: 'Itens por pagina',
                itemsPerPageOptions: [5, 10, 15],
                showFirstLastPage: true
            }">
            <template v-slot:item.item="{ item }">
                {{ parseFloat(item.item) }}
            </template>

            <template v-slot:item.value="{ item }">
                {{ formatMoney(item.value) }}
            </template>
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Custos</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Localizar"
                        @click:append="searchItems()" @change="searchItems()" single-line hide-details
                        class="mr-8"></v-text-field>
                    <v-dialog v-model="dialog" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">

                            <v-btn color="primary" rounded dark class="mb-2" v-bind="attrs" v-on="on"><v-icon
                                    small>mdi-plus</v-icon>Novo Custo</v-btn>
                        </template>
                        <v-card>
                            <div class="title_body">
                                <span class="text_title text-h6">{{ formTitle }}</span>
                                <span class="btn_close"><v-btn icon dark @click="dialog = false"><v-icon dark> mdi-close
                                        </v-icon></v-btn> </span>
                            </div>

                            <v-card-text>
                                <v-container>
                                    <v-form class="px-3" @submit.prevent="submit()">
                                        <v-row>
                                            <v-col><v-text-field v-model="formatName"
                                                    label="Nome"></v-text-field></v-col>
                                        </v-row>
                                    </v-form>
                                </v-container>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn class="mx-0 mt-3 mr-1" text @click="dialog = false">Fechar</v-btn>
                                <v-btn class="primary mx-0 mt-3" :loading="btnLoadingSave" :disabled="btnLoadingSave"
                                    @click="save">Salvar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-dialog v-model="dialogDelete" max-width="550px">
                        <v-card>
                            <v-card-title class="text-h5">Tem certeza de que deseja excluir este item ?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="" @click="closeDelete">Cancelar</v-btn>
                                <v-btn class="primary" @click="deleteItemConfirm">OK</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <!-- <v-btn x-small color="primary" outlined class="mr-2" @click="showListService(item)"><v-icon class="ml-1"
                        dark> mdi-eye </v-icon> </v-btn> -->
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="blue" class="mr-2" @click="cloneItem(item)" v-bind="attrs" v-on="on"> mdi-content-copy
                        </v-icon>
                    </template>
                    <span>Clonar</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon  class="mr-2" @click="showListStages(item)" v-bind="attrs" v-on="on"> mdi-altimeter
                        </v-icon>
                    </template>
                    <span>Etapas</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="success" class="mr-2" @click="showListService(item)" v-bind="attrs" v-on="on">
                            mdi-cash-multiple </v-icon>
                    </template>
                    <span>Orçamento</span>
                </v-tooltip>


                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary" class="mr-2" @click="editItem(item)" v-bind="attrs" v-on="on">
                            mdi-pencil </v-icon>
                    </template>
                    <span>Editar</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="error" class="mr-2" @click="deleteItem(item)" v-bind="attrs" v-on="on">
                            mdi-delete
                        </v-icon>
                    </template>
                    <span>Excluir</span>
                </v-tooltip>


            </template>
            <template v-slot:no-data> Nenhum registro encontrado </template>
        </v-data-table>
    </v-container>
</template>


<script>
import { mapActions } from "vuex/dist/vuex.common.js";
import service from "./service";
import { mapMutations } from 'vuex'
export default {
    data: () => ({
        search: "",
        totalStagesPaginate: 0,
        numberOfPagesPaginate: 0,
        currentPagePaginate: 0,
        options: {},
        btnLoadingSave: false,
        loading_data_table: false,
        dialog: false,
        dialogDelete: false,
        headers: [
            { text: "Nome", value: "name", align: "start", sortable: false },
            { text: "", value: "actions", sortable: false, align: "end" },
        ],
        costs: [],
        editedIndex: -1,
        editedItem: {
            id: "",
            name: "",
            value: 0
        },
        defaultItem: {
            id: "",
            name: "",
            value: 0
        },
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "Cadastrar Custo" : "Editar Custo";
        },
        formatName: {
            get: function () {
                return this.editedItem.name
            },
            set: function (name) {
                this.editedItem.name = name.toUpperCase()
            }
        }
    },

    watch: {
        options: {
            handler() {
                this.initialize();
            },
        },
        deep: true,
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
    },

    created() {
        this.initialize();
    },

    methods: {
        ...mapMutations('Cost', ['setCostId','cloneCost']),
        ...mapActions('Cost', ['cloneCost']),

        initialize() {
            this.loading_data_table = true;

            const { page, itemsPerPage } = this.options;

            service.getAll(itemsPerPage || 10, page, this.search).then((response) => {
                this.costs = response.data.data;
                this.totalStagesPaginate = response.data.total
                this.numberOfPagesPaginate = response.data.last_page
                this.currentPagePaginate = response.data.current_page
                this.loading_data_table = false;
            });
        },

        searchItems() {
            this.options = {}
            this.initialize()
        },

        showListService(item) {
            this.setCostId(item.id)
            this.$router.push('/system/budget/service')
        },

        showListStages(item) {
            this.setCostId(item.id)
            this.$router.push('/system/budget/service_stages')
        },
        editItem(item) {
            this.editedIndex = this.costs.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },

        deleteItem(item) {
            this.editedIndex = this.costs.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        cloneItem(item){
            
            this.cloneCost(item.id).then((response)=>{
                this.costs.push(response.data);
            }).finally(()=>{

            });
        },

        deleteItemConfirm() {
            let editedIndex = this.editedIndex;
            service.destroy(this.editedItem).then(() => {
                this.costs.splice(editedIndex, 1);
                this.$toast.success("Deletado com sucesso.");
                this.initialize()
            })

            this.closeDelete();
        },

        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;

            });
        },
        save() {
            this.btnLoadingSave = true;
            if (this.editedIndex > -1) {
                //update
                let indice = this.editedIndex;
                service.update(this.editedItem).then((response) => {
                    this.btnLoadingSave = false;
                    Object.assign(this.costs[indice], response.data);
                    this.close();
                    this.$toast.success("Salvo com sucesso.");
                }).catch(() => {
                    this.btnLoadingSave = false;
                })
            } else {
                //store
                service
                    .store(this.editedItem)
                    .then((response) => {
                        this.btnLoadingSave = false;
                        this.costs.push(response.data);
                        
                        this.close();
                        this.$toast.success("Salvo com sucesso.");
                        if (this.costs.length == 1) { this.initialize() }
                    })
                    .catch(() => {
                        this.btnLoadingSave = false;
                    })
            }

        },
        formatMoney(number) {
            number = this.parseNumber(number)
            return number.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        },
        parseNumber(number) {
            if (isNaN(number)) {
                return 0;
            }
            let result = parseFloat(number);
            return result;
        },

    },
};
</script>
